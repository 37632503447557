<template>
  <div class="new">
    <!-- <div class="new-banner">
      <img src="http://0vr1.com/video/2021-06-21/2021/FrontPic/img/banner.fd76be7b.png" v-if="$isMobile()" />
      <img src="http://0vr1.com/video/2021-06-21/2021/FrontPic/img/banner.f8493f10.png" v-else>
      <div class="new-banner-title">
        <h3>教VR企业范例</h3>
      </div>
    </div> -->
    <div class="new-breadcrumb">
      当前位置:<span @click="$router.push({ path: '/' })">首页</span> -> <span
        @click="$router.push({ path: 'vr-teach' })">教VR企业范例</span> -> <span>详情</span>
    </div>
    <div class="new-detail">
      <div class="new-detail-title">
        {{ info.title }}
      </div>
      <div class="new-detail-content ql-editor" v-html="info.content" :style="$isMobile() ? 'padding:15px 20px;' : ''">

      </div>
      <div class="new-detail-but" @click="$router.back()">
        返回列表
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'vrTeachDetail',
  data() {
    return {
      info: {}
    }
  },
  computed: {
    newTabItem() {
      return this.$route.query.type
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      const id = this.$route.query.id
      let url = `/examp/${id}`
      let _url = decodeURIComponent(this.$route.query.url)
      if (_url) {
        url = _url.replace('/api/tan', '')
      }
      console.log(url);
      this.$http({
        url
      }).then(res => {
        this.info = res.data
      })
    }
  }
}
</script>
<style scoped lang="scss">
.new {
  position: relative;

  &-banner {
    width: 100%;
    position: relative;
    width: 100%;

    img {
      width: 100%;
    }

    &-title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
      text-align: center;

      h3 {
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.8;
      }

      span {
        font-size: 1em;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        width: 400px;
        display: inline-block;
      }
    }
  }

  &-breadcrumb {
    max-width: 1100px;
    margin: 0 auto;
    padding: 12px 0;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    text-align: left;
    cursor: pointer;
    font-family: Microsoft YaHei;
  }

  &-detail {
    max-width: 1100px;
    margin: 0 auto;
    background: #fff;

    &-title {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      color: #333333;
      padding: 30px 0;
      border-bottom: 1px dotted #979797;
    }

    &-but {
      padding: 12px 0;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      cursor: pointer;
    }

    &-content {
      max-width: 1000px;
      margin: 0 auto;
      padding-top: 30px;

    }
  }
}</style>